import { Container } from "./ui/container";
import { H3, Paragraph } from "~/components/ui/typography";
import { Iframe } from "./iframe";
import { Icon } from "./ui/icons";
import { useSharedContent } from "~/hooks/localization";
import { Dictionary } from "~/utils/language";
import { IconLink } from "./ui/icon-link";

interface NewsletterProps {
	hideSubscribe?: boolean;
}

export function Newsletter({ hideSubscribe = false }: NewsletterProps) {
	const { t } = useSharedContent(Dictionary.NEWSLETTER);

	return (
		<Container
			noPadding
			className="bg-secondary flex flex-col items-center gap-layout4 rounded p-7 md:flex-row"
		>
			<div className="flex-1">
				<H3 className="mb-5">{t("newsletterTitle")}</H3>
				<Paragraph color="secondary">{t("newsletterText")}</Paragraph>
			</div>
			<div className="flex-1">
				<div className="min-h-[95px]">
					<Iframe
						title="Newsletter subscription"
						src="https://go.aiven.io/l/890043/2022-01-19/76c87"
						minHeight={95}
						isPardotForm={true}
						pardotFormType="newsletter"
					/>
				</div>
				{!hideSubscribe && (
					<div className="border-stroke border-t pt-5">
						<IconLink
							prefetch="none"
							reloadDocument
							to="/blog/feed.xml"
							target="_blank"
							iconRight={
								<Icon name="rss" width="14" height="14" color="primary" />
							}
						>
							{t("rssSubscribeText")}
						</IconLink>
					</div>
				)}
			</div>
		</Container>
	);
}
